jQuery(function () {

	// mobile menu
	$('#main-menu').slb_mobile_drop_menu('md');

	// function formGracias(){
	// 	if(window.location.hash == '#contact-form') {
	// 		$('.sec-contacto .buttons').after('<p>Gracias por contactarnos</p>');
	// 	}
	// }

	function scrollPage() {

		jQuery('#mnu-contacto a').on('click',
			function (e) {
				e.preventDefault();

				var target = this.hash;
				var $target = $(target);

				jQuery('html, body').stop().animate({
					'scrollTop': $target.offset().top
				}, 900, 'swing', function () {
					window.location.hash = target;
				});
			});
	}

	function carruselCustomer() {

		if ($('#stamp-slider-h .swiper-slide').length > 1) {
			var homeswiper = new Swiper('#stamp-slider-h', {
				direction: 'horizontal',
				slidesPerView: 1,
				loop: false,
				parallax: true,
				loop: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',

				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			});
		}
	}

	



	function carruselTestimonial() {
		if ($('#andari-slider-h').length > 0) {
			var homeswiper = new Swiper('#andari-slider-h', {
				direction: 'horizontal',
				slidesPerView: 1,
				keyboardControl: true,
				mousewheelControl: true,
				pagination: '#andari-slider-h  .swiper-pagination',
				paginationClickable: true
			});
		}
	}

	function contactOrder() {

		if ($('.sec-contacto .contact-form').length > 0) {

			jQuery('.sec-contacto .contact-form').before('<div class="contact-top"></div>');
			jQuery('.sec-contacto .contact-top').append($(".title-contact"));
			jQuery('.sec-contacto .contact-top').append($(".subtitle-contact"));
			jQuery('.sec-contacto .contact-top').append($(".text-contact"));

			jQuery('.sec-contacto .contact-top').append($(".text-contact-title-ubicacion"));
			jQuery('.sec-contacto .contact-top').append($(".text-contact-text-ubicacion"));
		}
	}

	function formMessage() {
		var form = $('#contact-form');
		form.submit(function (e) {
			// prevent form submission

			$('#contacto').addClass("onload");

			e.preventDefault();
			$.ajax({
				url: form.attr('action'),
				type: form.attr('method'),
				dataType: 'html',
				data: form.serialize(),
				success: function (result) {
					$('#contacto .content-form ').append('<p id="msg-contact" class="msg-succes">Gracias por contactarnos</p>');
					$('#contacto').removeClass("onload");
				}
			});
		});
	}

	carruselTestimonial();
	scrollPage();
	contactOrder();
	formMessage();
	carruselCustomer();

});